import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: [
    '../content/vendor/bootstrap/css/bootstrap.min.css',
    '../content/vendor/font-awesome/css/font-awesome.min.css'
  ]
})
export class PrivacyComponent implements OnInit {

  loading: boolean;
  agreedPrivacy: boolean;

  headertext = 'PRIVACY.HEADER';
  errorMessage: any;

  constructor(private _Route: Router, private _routeParams: ActivatedRoute,  private toastr: ToastrService, private translate: TranslateService) {
  }

  ngOnInit(): void {
  }

}
