import { Component, OnInit } from '@angular/core';
import { DownloadRequestModel, DownloadResponseModel, HeadsizeDefinitionModel } from './models/download-models';
import { DownloadService } from './services/download.services';
import { Router, ActivatedRoute } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import * as FileSaver from "file-saver"; 
import { AppConfigService } from '../app-config/app-config.service';

@Component({
  templateUrl: './view-download.html',
  styleUrls: [
    '../content/vendor/bootstrap/css/bootstrap.min.css',
    '../content/vendor/font-awesome/css/font-awesome.min.css'
  ]
})

export class DetailDownloadComponent implements OnInit {
  DownloadCode: string;
  HeadsizeId: string;
  private _service;
  ProductModel: DownloadResponseModel = new DownloadResponseModel();
  Headsizes : Array<HeadsizeDefinitionModel> = new Array<HeadsizeDefinitionModel>();
  errorMessage: any;
  output: any;

  constructor(private _Route: Router, private _routeParams: ActivatedRoute, service: DownloadService, 
    private toastr: ToastrService, private translate: TranslateService, private appConfigService: AppConfigService) {
    this._service = service;
  }

  ngOnInit(): void {
    this.HeadsizeId = '';
    this.DownloadCode = this._routeParams.snapshot.params['DownloadCode'];
    var downloadRequest = new DownloadRequestModel();
    downloadRequest.DownloadCode = this.DownloadCode;
    downloadRequest.CurrentLanguage = this.translate.currentLang;
    this._service.GetProduct(downloadRequest).subscribe(
      allPeriod => {
        if((allPeriod.FileContent != null && allPeriod.FileContent != "")||(allPeriod.PresignedUrl != null && allPeriod.PresignedUrl != "")){
          this.ProductModel = allPeriod;
          // this.ProductModel.HeadsizeDefinitions.forEach(element => {
          //   let hs = new HeadsizeDefinitionModel();
          //   hs.HeadsizeId = element.HeadsizeId;
          //   if(this.translate.defaultLang=='en')
          //   {
          //     hs.Name = element.Name;
          //   }
          //   else{
          //     hs.Name = element.NameJp;
          //   }
          //   hs.EphotoWidth = element.EphotoWidth;
          //   hs.EphotoHeight = element.EphotoHeight;
          //   this.Headsizes.push(hs);
          // });
        }
        else{
          this.toastr.warning('Reprint code is not available!!!', '', { timeOut: 5000 });
          this._Route.navigate(['/home']);
        }
      },
      error => this.errorMessage = <any>error
    );
  }

  GetMinutesBetweenDates(startDate, endDate) {
    var diff = endDate - startDate;
    return (diff / 60000);
  }

  Download() {
    let data = JSON.parse(sessionStorage.getItem('downloadSession'));
    if(!data){
      this._Route.navigate(['/home']);
      return;
    }
    var currentTime = new Date();
    var minutes = this.GetMinutesBetweenDates(new Date(data.StartTime), currentTime);

    if(this.appConfigService.sessionTimeout < minutes){
      this._Route.navigate(['/home']);
      return;
    }

    var downloadRequest = new DownloadRequestModel();
    downloadRequest.DownloadCode = this.DownloadCode;
    downloadRequest.HeadsizeId = this.HeadsizeId == ''? '0': this.HeadsizeId;
    downloadRequest.CurrentLanguage = this.translate.currentLang;
    this._service.DownloadProduct(downloadRequest).subscribe(
      allPeriod => {
        if(allPeriod.PresignedUrl != null && allPeriod.PresignedUrl != ""){
          //console.log('PresignedUrl: ' + allPeriod.PresignedUrl);
          //this.toastr.success('Download image is success!', '', { timeOut: 3000 });
          //window.open(allPeriod.PresignedUrl);
          this.openInNewTab(allPeriod.PresignedUrl);
        }
        else if(allPeriod.FileContent != null && allPeriod.FileContent != "")
        {
          this.toastr.success('Download image is success!', '', { timeOut: 3000 });
          this.b64toFile(allPeriod.FileContent, 'product.jpg', { type: "image/jpeg" });
        }
        else
        {
          this.toastr.warning('Download image is fail!', '', { timeOut: 5000 });
          window.open('/#/fail')
        }
      },
      error => this.errorMessage = <any>error
    );
  }

  openInNewTab(url) {
    var a = document.createElement('a');
    document.body.appendChild(a);
    a.download = name;
    a.href = url;
    a.click();
  }

  b64toFile(b64Data, filename, contentType) {
    var sliceSize = 512;
    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);

      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    FileSaver.saveAs(new Blob(byteArrays, contentType), filename);
  }

  isValidImage(): boolean {

    if ((this.ProductModel.Preview == null || typeof (this.ProductModel.Preview) == 'undefined' || this.ProductModel.Preview == '')
        &&(this.ProductModel.PresignedUrl== null|| this.ProductModel.PresignedUrl == ''))
      return false;
    return true;
  }

  setLang(lang){
    this.translate.setDefaultLang(lang);
    this.Headsizes = new Array<HeadsizeDefinitionModel>();
    this.ProductModel.HeadsizeDefinitions.forEach(element => {
      let hs = new HeadsizeDefinitionModel();
      hs.HeadsizeId = element.HeadsizeId;
      if(lang=='en')
      {
        hs.Name = element.Name;
      }
      else
      {
        hs.Name = element.NameJp;
      }
      hs.EphotoWidth = element.EphotoWidth;
      hs.EphotoHeight = element.EphotoHeight;
      this.Headsizes.push(hs);
    });
  }
}
