import { Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { AppConfigService } from './app-config/app-config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./content/vendor/bootstrap/css/bootstrap.min.css',
    // './Content/vendor/font-awesome/css/font-awesome.min.css'
  ]
})
export class AppComponent {
  title = 'Photo-Me';

  constructor(private translate: TranslateService, private appConfigService: AppConfigService) {
    translate.setDefaultLang(appConfigService.defaultLanguage);
  }
}
