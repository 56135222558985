import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  private appConfig: any;

  constructor(private http: HttpClient) { }

  loadAppConfig() {
    if (environment.production) {
    return this.http.get('/assets/config.json')
      .toPromise()
      .then(data => {
        console.log("Read configuration!!!");
        this.appConfig = data;
      });
    }
    else {
      return this.http.get('/assets/config.development.json')
        .toPromise()
        .then(data => {
          this.appConfig = data;
        });
    }
  }

  get apiBaseUrl() {

    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }

    return this.appConfig.apiEndpoint;
  }

  get defaultLanguage() {

    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }

    return this.appConfig.defaultLanguage;
  }

  get sessionTimeout() {

    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }

    return this.appConfig.sessionTimeout;
  }
}
