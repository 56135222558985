import { Component, OnInit } from '@angular/core';
import { DownloadService } from './services/download.services';
import { Router, ActivatedRoute } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  templateUrl: './download-fail.html',
  styleUrls: [
    '../content/vendor/bootstrap/css/bootstrap.min.css',
    '../content/vendor/font-awesome/css/font-awesome.min.css'
  ]
})

export class DownloadFailComponent implements OnInit {

  private _service;

  errorMessage: any;
  output: any;

  constructor(private _Route: Router, private _routeParams: ActivatedRoute, service: DownloadService, private toastr: ToastrService, private translate: TranslateService) {
    this._service = service;
  }

  ngOnInit(): void {
    
  }

  setLang(lang){
    this.translate.setDefaultLang(lang);
  }
}
