import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';

import { FormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from './app.component';
import { LoaderComponent } from './common/loader/loader.component';
import { LoaderService } from './common/loader/loader.service';
import { LoaderInterceptor } from './interceptors/loader.interceptor';

import { AppConfigService } from './app-config/app-config.service';

import { DownloadComponent } from './download/download.component';
import { DownloadFailComponent } from './download/download-fail.component';
import { DetailDownloadComponent } from './download/view-download.component';

import { PrivacyComponent } from './privacy/privacy.component';

import {TranslateModule,TranslateLoader, TranslateParser} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { TranslateAppParser } from './shared/translateappparser.module';

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    DownloadComponent,
    DownloadFailComponent,
    DetailDownloadComponent,
    PrivacyComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    AppRoutingModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      },
      parser: { provide: TranslateParser, useFactory: createTranslateParser },
    }),
    RouterModule.forRoot([
      { path: 'home/:DownloadCode', component: DetailDownloadComponent },
      { path: 'home', component: DownloadComponent },
      { path: 'privacy', component: PrivacyComponent },
      { path: 'fail', component: DownloadFailComponent },
      { path: '', redirectTo: "home", pathMatch: 'full' },
      { path: '**', redirectTo: "home" },
    ], { useHash: true }),
  ],
  providers: [
    AppConfigService,
    LoaderService,
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AppConfigService],
      useFactory: (appConfigService: AppConfigService) => {
        return () => {
          //Make sure to return a promise!
          return appConfigService.loadAppConfig();
        };
      }
    },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function createTranslateParser():any {
  return new TranslateAppParser();
}
