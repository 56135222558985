import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { DownloadRequestModel, DownloadResponseModel, HeadsizeDefinitionModel } from '../models/download-models';
import { AppConfigService } from '../../app-config/app-config.service';
@Injectable({
  providedIn: 'root'
})

export class DownloadService {

  private data: any;
  private apiUrl: string;
  username: any;

  constructor(private http: HttpClient, private appConfigService: AppConfigService) {
    this.apiUrl = this.appConfigService.apiBaseUrl + "/api/Download/";
  }

  public GetProduct(productmodel: DownloadRequestModel) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<DownloadResponseModel>(this.apiUrl, productmodel, { headers: headers }).pipe(tap(data => data),
      catchError(this.handleError)
    );
  }

  public DownloadProduct(productmodel: DownloadRequestModel) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<DownloadResponseModel>(this.apiUrl, productmodel, { headers: headers }).pipe(tap(data => data),
      catchError(this.handleError)
    );
  }

  public GetAllHeadsize() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get<HeadsizeDefinitionModel[]>(this.apiUrl, { headers: headers }).pipe(tap(data => data),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    return throwError('Something bad happened; please try again later.');
  };

}
