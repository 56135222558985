export class DownloadRequestModel {
  public DownloadCode: string;
  public HeadsizeId: string;
  public CurrentLanguage: string;
}

export class DownloadResponseModel {
  public Preview: any;
  public FileContent: any;
  public PresignedUrl: any;
  public HeadsizeDefinitions: any;
}

export class HeadsizeDefinitionModel {
  public HeadsizeId: number;
  public Name: string;
  public Width: number;
  public Height: number;
  public EphotoWidth: number;
  public EphotoHeight: number;
}
