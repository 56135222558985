import { Component, OnInit } from '@angular/core';
import { DownloadRequestModel } from './models/download-models';
import { DownloadService } from './services/download.services';
import { Router, ActivatedRoute } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  templateUrl: './download.html',
  styleUrls: [
    '../content/vendor/bootstrap/css/bootstrap.min.css',
    '../content/vendor/font-awesome/css/font-awesome.min.css'
  ]
})

export class DownloadComponent implements OnInit {
  DownloadCode: string;
  private _service;
  ProductModel: DownloadRequestModel = new DownloadRequestModel();
  errorMessage: any;
  output: any;

  constructor(private _Route: Router, private _routeParams: ActivatedRoute, service: DownloadService, private toastr: ToastrService, private translate: TranslateService) {
    this._service = service;
    sessionStorage.removeItem('downloadSession');
  }

  ngOnInit(): void {
  }

  onSubmit() {
    if(this.ProductModel.DownloadCode && this.ProductModel.DownloadCode != ''){
      var currentTime = new Date();
      sessionStorage.setItem('downloadSession', JSON.stringify({ DownloadCode: this.ProductModel.DownloadCode, StartTime: currentTime }));
    }
    this._Route.navigate(['/home/' + this.ProductModel.DownloadCode]);
  }

  setLang(lang){
    this.translate.setDefaultLang(lang);
    
  }
}
